import React from 'react';
import { isEmpty } from 'lodash';
import Link from 'components/Link';
import { FooterQuery } from './query';
import { cloudinaryImg } from 'components/helper';
import i18nx from 'components/locale/i18nx';


const Footer = () => {
  const { node: { frontmatter } } = FooterQuery();
  return (
    <>
      <section className="ss_newsletter">
        <div className="container-fluid">
          <div className="ss_footer_news ss_box_bg">	
            <div className="row"> 
              <div className="col-md-7">
                <div className="ss_foot_news_one">
                  <h1>{i18nx('stay_connected_with_us')}</h1>
                  {/* <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit sed quia  magni</p> */}
                </div>
              </div> 
              <div className="col-md-5 d-flex align-self-center justify-content-end">
                <Link to='/contact-us' className="ss_btn">{i18nx('go')}</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="ss_section_footer">
        <div className="container-fluid">	
          <div className="row"> 
            <div className="col-lg-5 col-md-12">
              <div className="row"> 
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="ss_foot_sec">
                    <Link to="/">
                      <div className="logo-bg">
                        <img className="img-fluid" src={cloudinaryImg(frontmatter.logo, 'h_100')} alt="logo" />
                      </div>
                      </Link>
                    <p>{frontmatter.description}</p>
                    <ul className="social_share">
                      <li>
                        <a href="https://www.facebook.com/INTEGRATED.S.S/">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 310">
                            <g id="XMLID_834_">
                              <path id="XMLID_835_" d="M81.703,165.106h33.981V305c0,2.762,2.238,5,5,5h57.616c2.762,0,5-2.238,5-5V165.765h39.064
                              c2.54,0,4.677-1.906,4.967-4.429l5.933-51.502c0.163-1.417-0.286-2.836-1.234-3.899c-0.949-1.064-2.307-1.673-3.732-1.673h-44.996
                              V71.978c0-9.732,5.24-14.667,15.576-14.667c1.473,0,29.42,0,29.42,0c2.762,0,5-2.239,5-5V5.037c0-2.762-2.238-5-5-5h-40.545
                              C187.467,0.023,186.832,0,185.896,0c-7.035,0-31.488,1.381-50.804,19.151c-21.402,19.692-18.427,43.27-17.716,47.358v37.752H81.703
                              c-2.762,0-5,2.238-5,5v50.844C76.703,162.867,78.941,165.106,81.703,165.106z" />
                            </g>
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a href="https://wa.me/967775432129">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <g>
                              <path d="M256.064,0h-0.128C114.784,0,0,114.816,0,256c0,56,18.048,107.904,48.736,150.048l-31.904,95.104l98.4-31.456
                                C155.712,496.512,204,512,256.064,512C397.216,512,512,397.152,512,256S397.216,0,256.064,0z M405.024,361.504
                                c-6.176,17.44-30.688,31.904-50.24,36.128c-13.376,2.848-30.848,5.12-89.664-19.264C189.888,347.2,141.44,270.752,137.664,265.792
                                c-3.616-4.96-30.4-40.48-30.4-77.216s18.656-54.624,26.176-62.304c6.176-6.304,16.384-9.184,26.176-9.184
                                c3.168,0,6.016,0.16,8.576,0.288c7.52,0.32,11.296,0.768,16.256,12.64c6.176,14.88,21.216,51.616,23.008,55.392
                                c1.824,3.776,3.648,8.896,1.088,13.856c-2.4,5.12-4.512,7.392-8.288,11.744c-3.776,4.352-7.36,7.68-11.136,12.352
                                c-3.456,4.064-7.36,8.416-3.008,15.936c4.352,7.36,19.392,31.904,41.536,51.616c28.576,25.44,51.744,33.568,60.032,37.024
                                c6.176,2.56,13.536,1.952,18.048-2.848c5.728-6.176,12.8-16.416,20-26.496c5.12-7.232,11.584-8.128,18.368-5.568
                                c6.912,2.4,43.488,20.48,51.008,24.224c7.52,3.776,12.48,5.568,14.304,8.736C411.2,329.152,411.2,344.032,405.024,361.504z" />
                            </g>
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a href="https://twitter.com/INTEGRATED_S_S?s=09">
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="ss_foot_sec">
                    <h2 className="ss_foot_head">{frontmatter.column_1.headden}</h2>
                    <ul>
                      {!isEmpty(frontmatter.column_1.links) &&
                        frontmatter.column_1.links.map((col, i) => (
                          <li key={i}>
                            <a href={col.link}>
                              <i className="fas fa-circle" /> {col.label}
                            </a></li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="row"> 
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="ss_foot_sec">
                    <h2 className="ss_foot_head">{frontmatter.column_2.headden}</h2>
                    <ul>
                      {!isEmpty(frontmatter.column_2.links) &&
                        frontmatter.column_2.links.map((col, i) => (
                          <li key={i}>
                            <a href={col.link}>
                              <i className="fas fa-circle" /> {col.label}
                            </a></li>
                        ))}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="ss_foot_sec">
                    <h2 className="ss_foot_head">{frontmatter.column_3.headden}</h2>
                    <ul>
                      {!isEmpty(frontmatter.column_3.links) &&
                        frontmatter.column_3.links.map((col, i) => (
                          <li key={i}>
                            <a href={col.link}>
                              <i className="fas fa-circle" /> {col.label}
                            </a>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6">
                  {!isEmpty(frontmatter.contact_info) && (
                    <div className="ss_foot_sec">
                      <h2 className="ss_foot_head">{i18nx('contact_us')}</h2>
                      <ul>
                        <li>{i18nx('address')} : {frontmatter.contact_info.address}</li>
                        <li>{i18nx('email')} : {frontmatter.contact_info.email}</li>
                        <li>{i18nx('phone')} : {frontmatter.contact_info.phone}</li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Footer;
