import { isEmpty } from 'lodash';

export const storage = typeof localStorage !== 'undefined' ? localStorage : {};

export const getWindow = typeof window !== 'undefined' ? window : { location: {} };

export const languageKey = ((getWindow.location.pathname && getWindow.location.pathname.split('/')[1]) || 'en');

export const cloudinaryImg = (path, params) => {
  // params exp: w_20, h_20

  if (!params) {
    return path;
  }
  return path.replace('upload/', `upload/${params}/`);
}

export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});
