import React from 'react'
import { Helmet } from 'react-helmet';
import { languageKey } from './helper';

const SiteHelmet = () => (
  <Helmet>
    <link rel="stylesheet" href="/assets/css/fonts.css" />
    <link rel="stylesheet" href="/assets/css/fontawesome.min.css" />
    <link rel="stylesheet" href="/assets/css/magnific-popup.css" />
    <link rel="stylesheet" href="/assets/css/swiper.min.css" />
    <link rel="stylesheet" href="/assets/css/animate.css" />
    {/* <link rel="stylesheet" href="/assets/css/color2.css" /> */}
    {languageKey === 'ar' &&
    <link href="https://fonts.googleapis.com/css?family=Cairo:400,700&display=swap&subset=arabic" rel="stylesheet" />}
  </Helmet>
)

export default SiteHelmet;
