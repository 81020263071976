import React from 'react'
import Popper from 'components/Popper/index';
import Link from 'components/Link';
import i18nx from 'components/locale/i18nx';


const MenuBar = ({
  menuList
}) => (
<div className="mb-2 d-inline-block">
<Popper
  list
  placement="bottom-end"
  trigger={
    <div className="ss_menu_bar mx-2">
      <span />
      <span />
      <span />
    </div>
  }
>
  <Link to="/">{i18nx('home')}</Link>
  {menuList.map((menu, i) => (
    <Link path={`/${menu.link}`}>{menu.label}</Link>
  ))}
</Popper>
</div>
);

export default MenuBar;
