import React from 'react'
import { Link as Glink } from 'gatsby';
import { languageKey } from './helper';


const Link = ({
  children,
  to,
  path,
  slug,
  ...props
}) => {
  const getLang = languageKey || 'ar';
  let link = getLang;
  if (to) {
    link = `/${getLang + to}`;
  }
  if (slug) {
    if (getLang === 'en') {
      link = slug;
    } else {
      link = slug;
    }
  }
  if (path) {
    link = path;
  }

  return (
    <Glink
      to={link}
      {...props}
    >
      {children}
    </Glink>
  )
};

export default Link;
