
module.exports = {
  address: 'العنوان',
  blog_posts: 'المدونة',
  blog: 'المدونة',
  contact_us: 'اتصل بنا',
  email: 'البريد الالكتروني',
  home: 'الرئيسية',
  latest_news: 'أحدث الأخبار',
  new_products: 'منتجات جديده',
  phone: 'الهاتف',
  products: 'المنتجات',
  stay_connected_with_us: 'ابق على اتصال معنا',
  submit: 'إرسال',
  top_rated_products: 'المنتجات الأعلى تصنيفا',
  related_tags: 'العلامات ذات الصلة',
  get_started: 'إبدا الان',
  purchase_now: 'اشترِ الآن',
  thank_you_message_sent_successfully: 'شكرا، تم الارسال بنجاح',
  full_name: 'الاسم الكامل',
  subject: 'الموضوع',
  message: 'الرسالة',
  attachment: 'مرفق',
  go: 'تواصل اﻵن',
  no_products_section: 'لا توجد منتجات في هذا القسم',
}