import React from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { HeaderQuery } from './query';
import Link from 'components/Link';
import { cloudinaryImg, languageKey, storage, getWindow } from 'components/helper';
// import SearchBox from './SearchBox';
import i18nx from 'components/locale/i18nx';
import MenuBar from './MenuBar';


const Header = ({
  children,
  lang
}) => {
  const { node: { frontmatter } } = HeaderQuery();
  const setNewLocale = storage.Locale === 'en' ? 'ar' : 'en';
  const logo = 'https://res.cloudinary.com/integrated-sulution-for-solar-energy-systems/image/upload/v1608577385/logo-2021.png';
  function handleChangeLang() {
    storage.setItem('Locale', setNewLocale)
    getWindow.location.replace(`${getWindow.location.origin}/${setNewLocale}`)
  }

  React.useEffect(() => {
    storage.setItem('Locale', lang)
  }, [lang]);

  return (
    <section className="ss_index_one s_header">
      <div className="container-fluid">
        <div className="ss_header">
          <div className="row">
            <div className="col-4 align-self-center">
              <div className="ss_logo d-flex">
                <Link to="/">
                  <div className="header-logo">
                    <img className="img-fluid" src={cloudinaryImg(logo, 'h_100')} alt="logo" />
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-8 align-self-center">
              <div className="ss_menu ss_menu_two flex justify-content-end">
                <ul>
                  <li>
                    <Link to="/">{i18nx('home')}</Link>
                  </li>
                  {frontmatter.header.menu_list &&
                   frontmatter.header.menu_list.map((menu, i) => (
                      <li
                        key={i}
                        className={classNames(!isEmpty(menu.sub_menu) && 'ss_menuP')}
                      >
                        <Link path={`/${menu.link}`}>{menu.label}</Link>
                        {/* {!isEmpty(menu.sub_menu) ?
                          menu.label :
                          <Link to={menu.link}>{menu.label}</Link>
                        }
                        {!isEmpty(menu.sub_menu) && (
                          <ul className="ss_subMenu">
                            {menu.sub_menu.map((sub, subi) => (
                              <li key={subi}>
                                <Link to={sub.link}>{sub.label}</Link>
                              </li>
                            ))}
                          </ul>
                        )} */}
                      </li>
                    ))}
                </ul>
                <div className="head_icon flex">
                  {/* <SearchBox /> */}
                  <div className="ss_cart_ico">
                    <Link to="/products">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 510 510">
                        <g>
                          <path d="M153,408c-28.05,0-51,22.95-51,51s22.95,51,51,51s51-22.95,51-51S181.05,408,153,408z M0,0v51h51l91.8,193.8L107.1,306
                            c-2.55,7.65-5.1,17.85-5.1,25.5c0,28.05,22.95,51,51,51h306v-51H163.2c-2.55,0-5.1-2.55-5.1-5.1v-2.551l22.95-43.35h188.7
                            c20.4,0,35.7-10.2,43.35-25.5L504.9,89.25c5.1-5.1,5.1-7.65,5.1-12.75c0-15.3-10.2-25.5-25.5-25.5H107.1L84.15,0H0z M408,408
                            c-28.05,0-51,22.95-51,51s22.95,51,51,51s51-22.95,51-51S436.05,408,408,408z" />
                        </g>
                      </svg>
                    </Link>
                  </div>
                  <MenuBar menuList={frontmatter.header.menu_list || []} />
                  <button
                    onClick={handleChangeLang}
                    style={{
                      minWidth: 100,
                    }}
                    class="white_btn mx-2"
                  >
                    {languageKey === 'ar' ? 'English' : 'عربي'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {children}
      </div>
    </section>
  )
}

export default Header;
