
module.exports = {
  address: 'Address',
  blog_posts: 'Blog Posts',
  blog: 'Blog',
  contact_us: 'Contact Us',
  email: 'Email',
  home: 'Home',
  latest_news: 'Latest news',
  new_products: 'New Products',
  phone: 'Phone',
  products: 'Products',
  stay_connected_with_us: 'Stay connected with us',
  submit: 'Submit',
  top_rated_products: 'Top rated products',
  related_tags: 'Related Tags',
  get_started: 'Get Started',
  purchase_now: 'Purchase Now',
  thank_you_message_sent_successfully: 'Thank you,Your message has been sent successfully',
  full_name: 'Full name',
  subject: 'Subject',
  message: 'Message',
  attachment: 'Attachment',
  go: 'Go',
  no_products_section: 'There are no products in this section',
} 