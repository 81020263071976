import { languageKey } from 'components/helper';
import ar from './ar';
import en from './en';


function i18nx(key) {
  const trans = { ar, en };
  const translation = trans[languageKey]
  
  return translation[key]
};

export default i18nx;