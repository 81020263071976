import { useStaticQuery, graphql } from "gatsby"
import { languageKey } from '../helper';


export const HeaderQuery = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(filter: {frontmatter: {templateType: {eq: "header-setting"}}}) {
          edges {
            node {
              id
              fields{
                lang
              }
              frontmatter {
                templateType
                header {
                  logo
                  menu_list {
                    label
                    link
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const getbyLanguage = allMarkdownRemark.edges.find(
    data => data.node.fields.lang === languageKey,
  );

  return {
    node: getbyLanguage.node || {},
  };

}