import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PopperUI from '@material-ui/core/Popper';
import useOnClickOutside from 'components/hooks/useOnClickOutside';
import { languageKey } from 'components/helper';


const Popper = ({
  trigger,
  children,
  visibleInOnClick,
  list,
  placement,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const ref = React.useRef();
  const open = Boolean(anchorEl);
  const defaultPlacement = languageKey === 'ar' ? 'bottom-end' : 'bottom-start';

  useOnClickOutside(ref, () => setAnchorEl(null));

  function handleClick(event) {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  }

  function handlePoperClick() {
    if (!visibleInOnClick) {
      setAnchorEl(null);
    }
  }

  return (
    <div
      ref={ref}
      className="galm-popper"
    >
      <div
        onClick={handleClick}
      >
        {trigger}
      </div>
      <PopperUI
        open={open}
        anchorEl={anchorEl}
        placement={placement || defaultPlacement}
        onClick={handlePoperClick}
        className={classNames(
          'galm-popper__content',
          list && 'galm-popper__list',
        )}
      >
        {children}
      </PopperUI>
    </div>
  );
};

Popper.propTypes = {
  trigger: PropTypes.node,
  children: PropTypes.node,
  list: PropTypes.bool,
  visibleInOnClick: PropTypes.bool,
};

export default Popper;

